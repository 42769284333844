















import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';

@Component({
  components: { Header, GeneralCrudList },
})
export default class CustomQueries extends Vue {
  model: any = [
    { name: 'name', label: 'Nazwa', type: 'text' },
    { name: 'db', label: 'Baza danych', type: 'text' },
    { name: 'params', label: 'Parametry (oddzielone przecinkiem)', type: 'text' },
    {
      name: 'type',
      label: 'Typ odpowiedzi',
      type: 'select',
      options: [
        { id: 'record', name: 'Rekordy' },
        { id: 'sum', name: 'Suma' },
      ],
    },
    { name: 'sql', label: 'Zapytanie SQL', type: 'textarea' },
  ];
  listOptions: any = [
    { field: 'name', label: 'Nazwa' },
    { field: 'params', label: 'Parametry (oddzielone przecinkiem)' },
    { field: 'type', label: 'Typ odpowiedzi' },
  ];
}
